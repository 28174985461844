<template>
  <div>
    <v-navigation-drawer
      fixed
      app
      v-model="drawer"
      :mini-variant="mini"
      clipped
      mobile-breakpoint="0"
    >
      <v-card elevation="0" class="flex-none">
        <!-- SESSION -->
        <v-card-text v-if="!mini">
          <div class="headline mb-2">{{$t('drawer.current_session')}}</div>
          <div v-if="auth.credentials">
            <v-chip label outlined>{{ auth.credentials.operator.name }}</v-chip>
            <v-icon v-if="operatorShift && operatorShift.role === operatorRoles.SUPERVISOR" class="ml-2">mdi-shield-account</v-icon>
            <div class="mt-2">
              <a class="body-2 grey--text" @click="$store.dispatch('auth/signOut')"><v-icon class="mr-2 subheading">mdi-power</v-icon>{{$t('actions.sign_out')}}</a>
            </div>
          </div>
          <v-layout v-else align-center justify-center>
            <v-progress-circular indeterminate color="primary" />
          </v-layout>
        </v-card-text>
        <!-- open/close shift, error and warnings (mini mode) -->
        <v-layout v-else column align-center justify-center>
          <template v-if="auth.credentials">
            <v-btn fab icon tile depressed @click="$store.dispatch('auth/signOut')">
              <v-icon class="subheading">mdi-power</v-icon>
            </v-btn>
            <div v-if="!operatorShift && !printerShift" @click="() => notConfigured ? mini = !mini : ''" :class="notConfigured ? 'clickable' : ''">
              <v-btn fab icon tile depressed @click="openShift()" :disabled="notConfigured">
                <v-icon class="subheading">mdi-badge-account-alert</v-icon>
              </v-btn>
            </div>
            <v-btn v-else fab icon tile depressed @click="closeShift(!unmatchingShifts ? operatorShift.id : printerShift ? printerShift.id : operatorShift.id)">
              <v-icon class="subheading">mdi-badge-account</v-icon>
            </v-btn>
            <v-btn v-if="disconnectedPeripheralService" fab tile dark depressed color="error" @click="mini = !mini">
              <v-icon>mdi-alert</v-icon>
            </v-btn>
            <v-btn v-if="isLongShift || (unmatchingShifts && (printerShift || operatorShift)) || notConfigured" fab tile depressed color="warning" @click="mini = !mini">
              <v-icon>mdi-exclamation</v-icon>
            </v-btn>
          </template>
          <v-progress-circular v-else class="ma-2" indeterminate color="primary" />
        </v-layout>
        <v-divider/>
        <!-- PERIPHERALS CONTROLLER AND SHIFT (expanded mode)-->
        <v-card-text v-if="!mini">
          <v-alert v-if="disconnectedPeripheralService" label type="error" outlined class="my-2">{{ $t('peripherals.warnings.disconnected_peripheral_service') }}</v-alert>
          <div class="headline mb-2">{{$t('drawer.current_shift')}}</div>
          <div v-if="shifts.finishedLoadingShifts">
            <v-chip label outlined v-if="printerShift" class="mt-2">{{ $t('shifts.opened_at_date', { date: dayjs(printerShift.openedAt).format('DD/MM/YYYY HH:mm') }) }}</v-chip>
            <div v-if="isLongShift">
              <v-chip label color="warning" class="my-2">{{ $t('shifts.warnings.long_shift') }}</v-chip>
              <v-icon class="ml-2" color="warning">mdi-alert-outline</v-icon>
            </div>
            <v-btn @click="openShift()" :disabled="notConfigured" color="primary" outlined v-if="!operatorShift && !printerShift">{{$t('shifts.actions.open')}}</v-btn>
            <div v-else class="mt-2">
              <!-- Todo correcto (turno de impresora abierto y coincide con operario) -->
              <v-btn small color="primary" outlined v-if="!unmatchingShifts" class="mb-2" @click="closeShift(operatorShift.id)">{{$t('shifts.actions.close')}}</v-btn>
              <template v-else>
                <!-- La impresora tiene un turno abierto para otro operario -->
                <template v-if="printerShift">
                  <v-alert :value="true" type="warning">
                    {{ $t('shifts.errors.device_has_active_shift') }}
                  </v-alert>
                  <v-btn small color="primary" outlined class="mb-2" @click="closeShift(printerShift.id)">{{$t('shifts.actions.close_printer')}}</v-btn>
                </template>
                <!-- El operario tiene un turno abierto en una impresora que no coincide con la actual -->
                <template v-else-if="operatorShift">
                  <v-alert :value="true" type="warning">
                    {{ $t('shifts.errors.operator_has_active_shift', { device: externalPrinterName }) }}
                  </v-alert>
                  <v-btn small color="primary" outlined v-if="operatorShift" class="mb-2" @click="closeShift(operatorShift.id)">{{$t('shifts.actions.close_operator') }}</v-btn>
                </template>
              </template>
            </div>
          </div>
          <v-layout v-else align-center justify-center>
            <v-progress-circular indeterminate></v-progress-circular>
          </v-layout>
        </v-card-text>
      </v-card>
      <no-configuration-alert v-if="!mini" :show="notConfigured && $route.name !== routeNames.settings.name" class="mx-4" />
      <v-list dense>
        <template v-for="route in menuLinks">
          <v-list-item v-if="route.show"
            active-class="primary--text text--darken-1"
            :key="JSON.stringify(route)"
            :to="getRouteName(route)"
            @click="mini = true"
          >
            <v-list-item-action>
              <v-icon>{{ route.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{route.text}}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
      <v-spacer @click="mini = false" />
      <reader-overlay v-if="!disconnectedPeripheralService && selectedReader"
        @data="(data) => goToGuest(data.guest_id)" action="GET_GUEST_ID_FROM_KEY"
        :class="{ 'ma-2': !mini }"
        @buttonClick="mini = !mini"
        :button="mini"
        inline
      />
      <div v-if="!mini" class="grey--text pa-2 text-center caption">
        Copyright © easygoband World S.L. 2021<br/>
        v{{pjson.version}}
      </div>
    </v-navigation-drawer>
    <v-app-bar v-if="$route.name" flat color="primary pl-0" app clipped-left>
      <v-btn @click="mini = !mini" outlined dark class="dense mr-3">{{$t('actions.menu')}}</v-btn>
      <logo v-if="$vuetify.breakpoint.mdAndUp" theme="dark" class="login-logo mx-5" height="30" style="height: 30px; width: auto;"/>
      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      <v-toolbar-title class="headline white--text d-flex">
        <template v-if="$vuetify.breakpoint.smAndUp">
          <span v-if="routeNames[$route.name].section" class="font-weight-light">{{$t(`sections.${routeNames[$route.name].section.name}`)}}</span>
          <span v-if="routeNames[$route.name].section" class="mx-2" style="border-left: 1px solid white;"></span>
          <span>{{$t(`sections.${$route.name}`)}}</span>
        </template>
        <template v-else>
          <v-layout column class="text-xs-right py-2">
          <v-flex xs12 class="title text-ellipsis white--text" v-if="routeNames[$route.name].section">{{$t(`menu.${routeNames[$route.name].section.name}`)}}</v-flex>
          <v-flex xs12 style="line-height: 100%;" class="font-weight-light body-2 white--text">{{$t(`sections.${$route.name}`)}}</v-flex>
          </v-layout>
          <span class="mx-2" style="border-left: 1px solid white;"></span>
        </template>
      </v-toolbar-title>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"/>
    </v-app-bar>
    <confirm-modal
      :title="!unmatchingShifts ? $t('shifts.actions.close') : printerShift ? $t('shifts.actions.close_printer') : $t('shifts.actions.close_operator')"
      :triggerOpen="confirmCloseShift"
      @accept="continueProcess()"
    />
    <supervisor-modal :triggerOpen="openSupervisorModal" @finished="continueProcess()"/>
    <open-shift-modal :triggerOpen="openShiftModal" inNavigationMenu/>
    <close-shift-modal :triggerOpen="closeShiftModal" :shiftId="closingShiftId" inNavigationMenu />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import pjson from '@/../package.json'
import { mapState } from 'vuex'
import routeNames from '@/router/routes'
import permissionMixins from '@/mixins/Permission'
import scrollMixins from '@/mixins/Scroll'
import shiftMixins from '@/mixins/Shifts'
import { operatorRoles } from '@/config'
export default {
  data () {
    return {
      pjson,
      dayjs,
      routeNames,
      drawer: true,
      mini: true,
      menuLinks: [
        {
          icon: routeNames.ticket_order.icon,
          text: this.$t('sections.ticket_order'),
          name: 'ticket_order',
          routeName: routeNames.ticket_order.name,
          show: false
        },
        {
          icon: routeNames.ticket_validation.icon,
          text: this.$t('sections.ticket_validation'),
          name: 'ticket_validation',
          routeName: routeNames.ticket_validation.name,
          show: false
        },
        {
          icon: routeNames.shifts.icon,
          text: this.$t('sections.shifts'),
          name: 'shifts',
          routeName: routeNames.shifts.name,
          show: false
        },
        {
          icon: routeNames.refund.icon,
          text: this.$t('sections.refund'),
          name: 'refund',
          routeName: routeNames.refund.name,
          show: false
        },
        {
          icon: routeNames.refund_requests.icon,
          text: this.$t('sections.refund_requests'),
          name: 'refund_requests',
          routeName: routeNames.refund_requests.name,
          show: false
        },
        {
          icon: routeNames.settings.icon,
          text: this.$t('sections.settings'),
          name: 'settings',
          routeName: routeNames.settings.name,
          show: false
        }
      ],
      selectedFacility: null,
      selectedStation: null,
      operatorRoles
    }
  },
  mounted () {
    this.$store.dispatch('configuration/initialization').finally(() => {
      this.getAllowedRoutes()
    })
  },
  mixins: [
    permissionMixins,
    scrollMixins,
    shiftMixins
  ],
  components: {
    Logo: () => import('@/components/app-components/Logo.vue'),
    NoConfigurationAlert: () => import('@/components/view-components/AlertNoConfiguration'),
    SupervisorModal: () => import('@/components/view-components/modals/SupervisorModal.vue'),
    OpenShiftModal: () => import('@/components/view-components/modals/OpenShiftModal.vue'),
    ConfirmModal: () => import('@/components/app-components/modals/ConfirmModal.vue'),
    CloseShiftModal: () => import('@/components/view-components/modals/CloseShiftModal.vue'),
    ReaderOverlay: () => import('@/components/view-components/ReaderOverlay.vue')
  },
  computed: {
    ...mapState({
      auth: 'auth',
      disconnectedPeripheralService: state => state.peripherals.disconnectedPeripheralService,
      selectedReader: state => state.peripherals.selectedReader,
      reader: state => state.state.reader
    }),
    isLongShift () {
      return this.shifts.finishedLoadingShifts && this.printerShift && dayjs().diff(dayjs(this.printerShift.openedAt), 'h') >= 12
    }
  },
  methods: {
    getRouteName (route) {
      let routeName = route.routeName ? { name: route.routeName } : {}
      if (route.routeNames) {
        const firstAllowedRouteName = route.routeNames.find((routeName) => {
          const r = this.getRoute(routeName)
          return this.isRouteAllowed(r)
        })
        if (firstAllowedRouteName) {
          const r = this.getRoute(firstAllowedRouteName)
          routeName = { name: r.name }
        }
      }
      return routeName
    },
    getRoute (routeName) {
      return this.$router.options.routes.find(route => route.name === routeName)
    },
    isSectionAllowed (section) {
      if (section.routeNames) {
        return section.routeNames.some(routeName => {
          const route = this.getRoute(routeName)
          return this.isRouteAllowed(route)
        })
      } else {
        const route = this.getRoute(section.routeName)
        return this.isRouteAllowed(route)
      }
    },
    isRouteAllowed (route) {
      const hasPermission = route.meta && route.meta.resourceAlias ? this.isPermissionActive(route.meta.resourceAlias) : true
      return hasPermission && (route.meta && route.meta.onlySupervisor ? this.isSupervisor : true)
    },
    goToGuest (id) {
      if (id) {
        this.$router.push({ name: routeNames.guest.name, params: { id } })
      }
    },
    scrollDrawerToBottom () {
      this.$nextTick(() => {
        this.scrollToBottom({ elSelector: '.v-navigation-drawer', containerSelector: '.v-navigation-drawer__content' })
      })
    },
    getAllowedRoutes () {
      this.menuLinks.forEach((menuSection) => {
        if (menuSection.subsections) {
          menuSection.subsections.forEach((section) => {
            section.show = this.isSectionAllowed(section)
          })
        } else if (menuSection.routeName) {
          menuSection.show = this.isSectionAllowed(menuSection)
        }
      })
    }
  },
  watch: {
    reader: {
      deep: true,
      handler: function () { this.scrollDrawerToBottom() }
    },
    isSupervisor () {
      this.getAllowedRoutes()
    }
  }
}
</script>
